import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-react-intl";


const ReadMore = ({ children }) => {
  const intl = useIntl();

  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };


  return (
    <div className="read-more-component">
      <div className="text" dangerouslySetInnerHTML={{
        __html: `${isReadMore ? text?.slice(0, 300) : text
          }`
      }} />
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? intl.formatMessage({ id: "read_more" }) : intl.formatMessage({ id: "show_less" })}
      </span>
    </div>
  );
};

export default ReadMore;