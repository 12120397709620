import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { optimizeImageUrl } from "../../../utils/cloudinary";
import { AdvancedImage, lazyload, responsive } from '@cloudinary/react';



const Slider = ({ images }) => {
  return (
    <Splide
      className="image event-thumb"
      options={{
        rewind: true,
        perPage: 3,
        perMove: 1,
        gap: '1.7rem',
        pagination: false,
        arrows: 'slider',
        lazyLoad: 'nearby',
        breakpoints: {
          1024: {
            perPage: 3,
          },
          600: {
            perPage: 1,
          },
        },
      }}
    >
      {images.map((image) => {
        return (
          <SplideSlide key={image}>
            <AdvancedImage className="slider-image" cldImg={optimizeImageUrl(image)} plugins={[responsive(), lazyload()]} />
          </SplideSlide>
        );
      })}
    </Splide>
  );
};

export default Slider;
