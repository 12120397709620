import React from "react";
import GoogleMapReact from 'google-map-react';
import { truncate as _truncate } from 'lodash';

const AnyReactComponent = ({ text }) => (
  <div className="google-map-marker">
    <p data-tooltip={text}>{_truncate(text, { length: 10 })}</p>
  </div>
);

const GoogleMap = ({ address, map_location }) => {
  const coordinate = map_location?.split(',');

  const defaultProps = {
    center: {
      lat: Number(coordinate[0]),
      lng: Number(coordinate[1]),
    },
    zoom: 12,
  };

  return (
    <div style={{ height: '250px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={Number(coordinate[0])}
          lng={Number(coordinate[1])}
          text={address}
        />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
